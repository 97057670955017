import React from "react";
import "./Footer.css";
import { IoLogoInstagram } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
const Footer = () => {
  return (
    <div className="footer-d1">
      <div className="footer-d2">
        <div className="footer-d3">
          <div className="footer-d4">
            <div className="footer-d5">
              <div className="logo-d-footer">
                <h1>Manppreet Singh</h1>
              </div>
              <p className="p-1-footer">
                ASCEND YOUR PHYSIQUE AND TRANSFORM YOUR LIFE WITH AMATURE OLYMPIA
                athlete MR OLYMPIA Manppreet Singh.
              </p>
              <div className="social-links">
                <div className="social-icon">
                  <a
                    href="https://wa.me/+917743043553?text=My name is [Your Name], I am [Your Age] years old, [Your Gender], and I weigh [Your Weight]. I am very interested in your coaching services. Could you please provide me with more information about the programs you offer and how they could benefit me?"
                    target="blank"
                  >
                    <IoLogoWhatsapp color="black" size={30} />
                  </a>
                </div>
          

                <div className="social-icon">
                  <a
                    href="https://www.instagram.com/fitnessmanppreet/"
                    target="blank"
                  >
                    <IoLogoInstagram color="black" size={30} />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    href="https://www.facebook.com/manppreet.nijjar.90?mibextid=LQQJ4d"
                    target="blank"
                  >
                    <FaFacebook color="black" size={30} />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                   href="tel:+917743043553"
                    target="blank"
                  >
                    <IoCallOutline color="black" size={30} />
                  </a>
                </div>
              </div>
            </div>

            <div className="footer-d6">
              <h1>IMPORTANT LINKS</h1>

              <ul className="footer-ul">
                <li>
                  <Link to={"/Health"} className="footer-link">
                    <FaLongArrowAltRight color="#ea1b29" />
                    HEALTH SUPPORT PROGRAMS
                  </Link>
                </li>
                <li>
                  <Link to={"/Muscel"} className="footer-link">
                    <FaLongArrowAltRight color="#ea1b29" />
                    MUSCLE BUILD / FAT LOSS PROGRAM
                  </Link>
                </li>
                <li>
                  <Link to={"/Competition"} className="footer-link">
                    <FaLongArrowAltRight color="#ea1b29" />
                    COMPETITION COACHING PROGRAM
                  </Link>
                </li>
                <li>
                  <Link to={"/EBook"} className="footer-link">
                    <FaLongArrowAltRight color="#ea1b29" />
                    E-BOOK
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-d6">
              <h1>POLICY LINKS</h1>

              <ul className="footer-ul">
                <li>
                  <Link to={"/Terms-conditions"} className="footer-link">
                    <FaLongArrowAltRight color="#ea1b29" />
                    TERMS & CONDITIONS
                  </Link>
                </li>

                <li>
                  <Link to={"/Refund-return-policy"} className="footer-link">
                    <FaLongArrowAltRight color="#ea1b29" />
                    REFUND POLICY
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="copy-right-footer">
            <p>COPYRIGHT © 2024 Manppreet Nijjar – ALL RIGHTS RESERVED</p>
            <p className="p-a-f1">
              DESIGNED BY
              <a href="https://akstudioss.com/" target="blank">
                {" "}
            AKSTUDIOSS
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
