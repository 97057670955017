import React from "react";
import "./EBook.css";
import { IoIosCall } from "react-icons/io";
import { FaRegCircleCheck } from "react-icons/fa6";
import ReactWhatsapp from "react-whatsapp";
import Currency from "react-currency-formatter";

const EBook = () => {
  const plans = [
    {
      id: 1,
      package: "15 Minutes",
      price: "250",
      description:
        "We often work one-on-one with individuals to assess their needs and develop a lifestyle strategy that can help them meet fitness goals, like weight loss or muscle gain.",
      point1: "Any thing about PED protocol / drug protocol ",
      point2: "blood reports analysis",
      point3: "supplements guidance",
      point4: "diet and workout guidance",
    },
    {
      id: 2,
      package: "30 Minutes",
      price: "350",
      description:
        "We often work one-on-one with individuals to assess their needs and develop a lifestyle strategy that can help them meet fitness goals, like weight loss or muscle gain.",
      point1: "Any thing about PED protocol / drug protocol ",
      point2: "blood reports analysis",
      point3: "supplements guidance",
      point4: "diet and workout guidance",
    },
    {
      id: 3,
      package: "45 Minutes",
      price: "500",
      description:
        "We often work one-on-one with individuals to assess their needs and develop a lifestyle strategy that can help them meet fitness goals, like weight loss or muscle gain.",
      point1: "Any thing about PED protocol / drug protocol ",
      point2: "blood reports analysis",
      point3: "supplements guidance",
      point4: "diet and workout guidance",
    },
    {
      id: 4,
      package: "60 Minutes",
      price: "650",
      description:
        "We often work one-on-one with individuals to assess their needs and develop a lifestyle strategy that can help them meet fitness goals, like weight loss or muscle gain.",
      point1: "Any thing about PED protocol / drug protocol ",
      point2: "blood reports analysis",
      point3: "supplements guidance",
      point4: "diet and workout guidance",
    },
  ];
  return (
    <div>
      <div className="ebook2">
        <h1>E-book</h1>
      </div>
      <div className="ebook6">
        <div className="ebook5">
          <h1>
            Choose the plan that fits <br />
            the needs.
          </h1>
        </div>
        <div className="book3">
          <div className="book4">
            <div className="book7">
              {plans.map((item) => (
                <>
                  <div className="book-card1">
                    <div className="card-bookd2">
                      <div className="card-min1">
                        <h1 className="headcard-book">{item.package}</h1>
                      </div>

                      <div className="price-book">
                        <h1>
                        <Currency quantity={item.price} currency="inr" />
                          
        
                          </h1>
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div>
                      <ul className="card-book-ul">
                        <li className="card-li-book">
                          <FaRegCircleCheck size={15} />
                          {item.point1}
                        </li>
                        <li className="card-li-book">
                          <FaRegCircleCheck size={15} />
                          {item.point2}
                        </li>
                        <li className="card-li-book">
                          <FaRegCircleCheck size={15} />
                          {item.point3}
                        </li>
                        <li className="card-li-book">
                          <FaRegCircleCheck size={15} />
                          {item.point4}
                        </li>
                      </ul>
                      <div className="choose-btn-book">
                        <ReactWhatsapp number={"+91 7743-043553"} message={`Hello, I'd like to choose the ${item.package} call plan for the date/time: DD-MM-YY and HH-MM .`}>
                          Choose
                        </ReactWhatsapp>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EBook;
