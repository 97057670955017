import React from "react";
import "./Plan.css";
import { FaCheck } from "react-icons/fa6";
import ReactWhatsapp from "react-whatsapp";
import Currency from "react-currency-formatter";

const Plan = ({ data }) => {
  return (
    <div className="plan-d1">
      <h1 className="choose-plan-h1">CHOOSE YOUR PLAN</h1>

      <div className="plan-d8">
        <h1>Availability: 5 slots per month</h1>
        <p>
          Process: Once enrolled, MANPPREET Singh will personally get in touch
          with you to take things forward. <br /> Please don't rush and allow at
          least 7-10 days to design and craft your program since everything is
          individualised and in-depth.
        </p>
      </div>

      <div className="plan-d2">
        <div className="plan-d3">
          <div className="plan-d4">
            {data.map((item) => (
              <>
                <div className="plan-d5">
                  <img className="plan-card-img" src={item.image} alt="" />

                  <div className="plan-d6">
                    <div>
                      <h1 className="card-title">{item.title}</h1>
                      <h1 className="card-title">
                        <Currency quantity={item.price} currency="inr" />
                      </h1>
                    </div>

                    <ul className="ul-card-plan">
                      <li className="card-li-price">{item.point1}.</li>
                      <li className="card-li-price">{item.point2}.</li>
                      <li className="card-li-price">{item.point3}.</li>
                      <li className="card-li-price">{item.point4}.</li>
                      <li className="card-li-price">{item.point5}.</li>
                      <li className="card-li-price">{item.point6}.</li>
                      <li className="card-li-price">{item.point7}.</li>
                      <li className="card-li-price">{item.point8}.</li>
                      {item.point9 && (
                        <li className="card-li-price">{item.point9}.</li>
                      )}

                      {item.point10 && (
                        <li className="card-li-price">{item.point10}.</li>
                      )}

                      {item.point11 && (
                        <li className="card-li-price">{item.point11}.</li>
                      )}
                      {item.point11 && (
                        <li className="card-li-price">{item.point12}.</li>
                      )}
                    </ul>

                    <ReactWhatsapp
                      className="join-btn-card"
                      number={"+91 7743-043553"}
                      message={`Hello, I'm interested in joining a ${item.title} .`}
                    >
                      Join
                    </ReactWhatsapp>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
