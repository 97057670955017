import React from "react";
import "./Programs.css";
import Working from "../../Components/Working/Working";
import Plan from "../../Components/Plan/Plan";
import ReactWhatsapp from "react-whatsapp";

const Program1 = () => {
  const data = [
    {
      id: 1,
      title: "4 week lifestyle ",
      price: "10000",
      image: require("../../assets/Img/img3.jpeg"),
      point1: " Super long questionnaire to get maximum insight",
      point2: " Blood work analysis (if required)",
      point3: " Breathing technique, mobility work, skin & hair rejuvenation",
      point4:
        "Focus on improving mental health, sleep/stress pattern and overall quality of life to make things easy and sustainable",
      point5:
        "Complete Nutrition: Based on your preferences, goals and eating habits",
      point6:
        " Training Schedule: Crafted accordance with your present level, training history, health, equipment availability",
      point7:
        "Cardio and supplement protocol for optimal health, recovery and hormonal imbalances",
      point8:
        "  Weekly check-ins where I will monitor your progress and make changes (if required)",
      point9:
        "Secret tips and tricks I have learned during my 11 years of training and coaching experience",
      point10: "Direct mentorship from NIJJAR through his personal WhatsApp ",
    },
    {
      id: 2,
      title: "8 week lifestyle ",
      price: "14000",
      image: require("../../assets/Img/img2.jpeg"),
      point1: " Super long questionnaire to get maximum insight",
      point2: " lood work analysis (if required)",
      point3: " Breathing technique, mobility work, skin & hair rejuvenation",
      point4:
        "Focus on improving mental health, sleep/stress pattern and overall quality of life to make things easy and sustainable",
      point5:
        "Complete Nutrition: Based on your preferences, goals and eating habits",
      point6:
        " Training Schedule: Crafted accordance with your present level, training history, health, equipment availability",
      point7:
        "Cardio and supplement protocol for optimal health, recovery and hormonal imbalances",
      point8:
        "  Weekly check-ins where I will monitor your progress and make changes (if required)",
      point9:
        "Secret tips and tricks I have learned during my 11 years of training and coaching experience",
      point10: "Direct mentorship from NIJJAR through his personal WhatsApp ",
    },
    {
      id: 2,
      title: "12 week lifestyle ",
      price: "17000",
      image: require("../../assets/Img/img6.jpeg"),
      point1: " Super long questionnaire to get maximum insight",
      point2: " lood work analysis (if required)",
      point3: " Breathing technique, mobility work, skin & hair rejuvenation",
      point4:
        "Focus on improving mental health, sleep/stress pattern and overall quality of life to make things easy and sustainable",
      point5:
        "Complete Nutrition: Based on your preferences, goals and eating habits",
      point6:
        " Training Schedule: Crafted accordance with your present level, training history, health, equipment availability",
      point7:
        "Cardio and supplement protocol for optimal health, recovery and hormonal imbalances",
      point8:
        "  Weekly check-ins where I will monitor your progress and make changes (if required)",
      point9:
        "Secret tips and tricks I have learned during my 11 years of training and coaching experience",
      point10: "Direct mentorship from NIJJAR through his personal WhatsApp ",
    },
  ];

  return (
    <div>
      <img
        className="img-health1"
        src="https://images.unsplash.com/photo-1519505907962-0a6cb0167c73?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTQzfHxib2R5YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D"
        alt=""
      />

      <div className="health-d1">
        <div className="health-d2">
          <div className="health-lh">
            <h1>
              MANPPREET'S PERSONALIZED <br /> HEALTH SUPPORT PROGRAMS
            </h1>
            <p>
              improve LIFESTYLE COACHING PROGRAM Focus on improving mental
              health, sleep/stress pattern and overall quality of life to make
              things easy and sustainable THE LIFESTYLE COACHING PROGRAM HAS
              BEEN CREATED SPECIFICALLY FOR THOSE INDIVIDUALS WHO WANT TO BE
              improve LIFESTYLE , internal organ heath , FEEL HEALTHY AND STAY
              fit . Focus on improving mental health, sleep/stress pattern and
              overall quality of life to make things easy and sustainable
            </p>

            <ReactWhatsapp
              number={"+91 7743-043553"}
              message={
                "Hello, I'm interested in joining a health support programs."
              }
            >
              ENROLL NOW
            </ReactWhatsapp>
          </div>

          <div className="health-rh">
            <img src={require("../../assets/Img/img6.jpeg")} alt="" />
          </div>
        </div>
      </div>

      <Working />

      <Plan data={data} />
    </div>
  );
};

export default Program1;
