import React from "react";
import "./Programs.css";
import Working from "../../Components/Working/Working";
import Plan from "../../Components/Plan/Plan";
import ReactWhatsapp from "react-whatsapp";
const Program3 = () => {
  const data = [
    {
      id: 1,
      title: "4 week competition coaching program",
      price: "13000",
      image: require("../../assets/Img/img3.jpeg"),
      point1: "SUPER LONG QUESTIONNAIRE TO GET MAXIMUM INSIGHT",
      point2: "BLOOD WORK ANALYSIS (IF REQUIRED)",
      point3: " BREATHING TECHNIQUE, MOBILITY WORK, SKIN & HAIR REJUVENATION",
      point4:
        "FOCUS ON IMPROVING MENTAL HEALTH, SLEEP/STRESS PATTERN AND OVERALL QUALITY OF LIFE TO MAKE THINGS EASY AND SUSTAINABLE",
      point5:
        "TO TEACH YOU EVERYTHING YOU NEED TO KNOW ABOUT FITNESS, HEALTH AND BODY RE-COMP",
      point6:
        "TRAINING SCHEDULE: CRAFTED ACCORDANCE WITH YOUR PRESENT LEVEL, TRAINING HISTORY, HEALTH, EQUIPMENT AVAILABILITY",
      point7:
        "CARDIO AND SUPPLEMENT PROTOCOL FOR OPTIMAL HEALTH, RECOVERY AND HORMONAL IMBALANCES",
      point8:
        "ROVIDE PED PROTOCOL",
      point9:
        "Provide Organ support protocol",
      point10: "⁠Provide PCT PROTOCOL",
      point11: " ⁠PEAK WEEK PROTOCOL",
      point12: "⁠ADVANCE RECOVERY PROTOCOL",

    },
    {
      id: 2,
      title: "8 week competition coaching program",
      price: "20000",
      image: require("../../assets/Img/img2.jpeg"),
      point1: "SUPER LONG QUESTIONNAIRE TO GET MAXIMUM INSIGHT",
      point2: "BLOOD WORK ANALYSIS (IF REQUIRED)",
      point3: " BREATHING TECHNIQUE, MOBILITY WORK, SKIN & HAIR REJUVENATION",
      point4:
        "FOCUS ON IMPROVING MENTAL HEALTH, SLEEP/STRESS PATTERN AND OVERALL QUALITY OF LIFE TO MAKE THINGS EASY AND SUSTAINABLE",
      point5:
        "TO TEACH YOU EVERYTHING YOU NEED TO KNOW ABOUT FITNESS, HEALTH AND BODY RE-COMP",
      point6:
        "TRAINING SCHEDULE: CRAFTED ACCORDANCE WITH YOUR PRESENT LEVEL, TRAINING HISTORY, HEALTH, EQUIPMENT AVAILABILITY",
      point7:
        "CARDIO AND SUPPLEMENT PROTOCOL FOR OPTIMAL HEALTH, RECOVERY AND HORMONAL IMBALANCES",
      point8:
        "ROVIDE PED PROTOCOL",
      point9:
        "Provide Organ support protocol",
      point10: "⁠Provide PCT PROTOCOL",
      point11: " ⁠PEAK WEEK PROTOCOL",
      point12: "⁠ADVANCE RECOVERY PROTOCOL",
    },
    {
      id: 3,
      title: "12 week competition coaching program ",
      price: "25000",
      image: require("../../assets/Img/img6.jpeg"),
      point1: "SUPER LONG QUESTIONNAIRE TO GET MAXIMUM INSIGHT",
      point2: "BLOOD WORK ANALYSIS (IF REQUIRED)",
      point3: " BREATHING TECHNIQUE, MOBILITY WORK, SKIN & HAIR REJUVENATION",
      point4:
        "FOCUS ON IMPROVING MENTAL HEALTH, SLEEP/STRESS PATTERN AND OVERALL QUALITY OF LIFE TO MAKE THINGS EASY AND SUSTAINABLE",
      point5:
        "TO TEACH YOU EVERYTHING YOU NEED TO KNOW ABOUT FITNESS, HEALTH AND BODY RE-COMP",
      point6:
        "TRAINING SCHEDULE: CRAFTED ACCORDANCE WITH YOUR PRESENT LEVEL, TRAINING HISTORY, HEALTH, EQUIPMENT AVAILABILITY",
      point7:
        "CARDIO AND SUPPLEMENT PROTOCOL FOR OPTIMAL HEALTH, RECOVERY AND HORMONAL IMBALANCES",
      point8:
        "ROVIDE PED PROTOCOL",
      point9:
        "Provide Organ support protocol",
      point10: "⁠Provide PCT PROTOCOL",
      point11: " ⁠PEAK WEEK PROTOCOL",
      point12: "⁠ADVANCE RECOVERY PROTOCOL",
    },
  ];

  return (
    <div>
      <img
        className="img-health1"
        src="https://images.unsplash.com/photo-1519505907962-0a6cb0167c73?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTQzfHxib2R5YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D"
        alt=""
      />

      <div className="health-d1">
        <div className="health-d2">
          <div className="health-lh">
            <h1>
              MANPPREET'S PERSONALIZED <br />
              COMPETITION COACHING PROGRAM
            </h1>
            <p>
              FOR THOSE LOOKING TO GET STAGE READY, WHETHER ITíS YOUR FIRST
              COMPETITION OR COMPETING AT THE PRO LEVEL, THIS PERSONALISED
              COMPETITION COACHING PLAN IS FOR YOU. WITH OVER 11 YEARS OF
              EXPERIENCE, YOUíRE SURE TO GET THE PERFECT DIET PLAN, EXERCISE &
              CARDIO ROUTINE HENCE REMOVING THE GUESSWORK FROM THE EQUATION.
              this Coaching plan is an elite level structured program for those
              who are done with everything and wants to live life not as an
              observer anymore, but as a titan - living as a true Specimen.
              Inside you will unlock all the secrets to sculpt a body that
              places you within the best tier of physical fitness, embodying
              strength, aesthetics, and perfect health.
            </p>
            <ReactWhatsapp
              number={"+91 7743-043553"}
              message={
                "Hello, I'm interested in joining a competition coaching program."
              }
            >
              ENROLL NOW
            </ReactWhatsapp>
          </div>

          <div className="health-rh">
            <img src={require("../../assets/Img/img3.jpeg")} alt="" />
          </div>
        </div>
      </div>
      <Working />
      <Plan data={data} />
    </div>
  );
};

export default Program3;
